let RoofProtect = (function () {

    function Toast(obj){
        let toastobj = obj || {}
        new Noty({
            layout: 'topRight',
            timeout: 3000,
            modal: false,
            type: toastobj.type || 'success',   // if no type specified, use 'success'
            text: toastobj.text || '...',       // if no text specified, use '...'
        }).show();
    }

    return {
        Toast: Toast    // publicly available as: RoofProtect.hello()
    };
})();

export default RoofProtect;
