require('./bootstrap');
// Make 'VinylShop' accessible inside the HTML pages
import Bugsnag from "@bugsnag/js";

window.Bugsnag = Bugsnag;
import RoofProtect from "./roofProtect";
window.RoofProtect = RoofProtect;
window.Noty = require('noty');
Noty.overrideDefaults({
    theme: 'bootstrap-v4',
    type: 'warning',
    layout: 'center',
    modal: true,
});

$('body').tooltip({
    selector: '[data-toggle="tooltip"]',
    html : true,
}).on('click', '[data-toggle="tooltip"]', function () {
    $(this).tooltip('hide');
});
